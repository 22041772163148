import { useCallback, useEffect, useState } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { XCircleIcon, ArrowLeftIcon, MapPinIcon } from '@heroicons/react/24/solid';
import Loader from '../Loader';
import { setCookie } from '../Helpers/SettingHelper';
import { useHistory, useLocation } from 'react-router-dom';
import { GpsIcon } from '../../utils/svg';
import { centerLocation } from '../../utils/constants';
import '../../tailwind.css';
import { getCurrentLocation } from '../../utils/location';

const mapOptions = {
	disableDefaultUI: true,
	zoomControl: false,
	mapTypeControl: false,
	fullscreenControl: false,
	streetViewControl: false,
	rotateControl: false,
};

const Footer = ({ isVisible, name, address, onClick, onClickCurrentLocation }) => {
	return (
		<>
			<div className={`absolute w-full bottom-0`}>
				<div className='flex justify-end mb-5 mr-3'>
					<div className='bg-white p-2 rounded-full cursor-pointer' onClick={onClickCurrentLocation}>
						<GpsIcon />
					</div>
				</div>
				<div className={`bg-white p-4 rounded-t-xl transition-transform duration-500 ease-in-out ${isVisible ? 'translate-y-0 relative' : 'translate-y-full hidden'}`}>
					<div className='flex justify-center'>
						<div className='bg-gray-300 h-2 w-10 rounded-full' />
					</div>
					<div className='mt-4 flex justify-between items-center'>
						<div className='text-gray-800 text-lg font-semibold'>Select Location</div>
						<button onClick={() => console.log('click edit footer')} className='border-1 border-[#732768] py-1.5 px-4 text-md font-semibold text-purple-700 hidden'>
							Edit
						</button>
					</div>
					<div className='mt-4 flex items-center'>
						<div className='pr-3'>
							<MapPinIcon class='h-6 w-6 text-gray-400' />
						</div>
						<div>
							<div className='text-gray-800 text-md font-semibold'>{name}</div>
							<div className='text-xs text-gray-500'>{address}</div>
						</div>
					</div>
					<button className='bg-[#732768] w-full text-white font-normal text-md py-3 border-0 mt-5' onClick={onClick}>
						Confirm Location
					</button>
				</div>
			</div>
		</>
	);
};

const CustomGoogleMap = () => {
	const history = useHistory();
  const locationDom = useLocation();
  const queryParams = new URLSearchParams(locationDom.search);
  const backPath = queryParams.get('back');
  console.log('backPath: ', backPath);

	const [zoom, setZoom] = useState(4);
	const [location, setLocation] = useState(null);
	const [name, setName] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isVisibleFooter, setIsVisibleFooter] = useState(false);
	const [address, setAddress] = useState(null);

	const {
		ready,
		value,
		suggestions: { status, data },
		setValue,
		clearSuggestions,
	} = usePlacesAutocomplete({
		debounce: 500,
		requestOptions: { types: [] },
	});

	useEffect(() => {
		if (ready) {
			setIsLoading(false);
		}
	}, [ready]);

	const onLoad = useCallback(() => {
		console.log('onLoad');
		setTimeout(() => {
			const elementsToHide = document.querySelectorAll('.gmnoprint');
			elementsToHide.forEach((el) => (el.style.display = 'none'));
		}, 1000);
	}, []);

	const handleOnClick = async (data) => {
		console.log('handleOnClick');
		const lat = data?.latLng?.lat();
		const lng = data?.latLng?.lng();
		const place_id = data?.placeId;
		if (lat && lng && place_id) {
			getGeocode({ placeId: place_id }).then((results) => {
				const result = results[0];
				handleFormatingAddress({
					lat,
					lng,
					formatted_address: result?.formatted_address || '',
					address_components: result?.address_components || {},
				});
			});
		}
	};

	const onUnmount = useCallback(function callback() {
		console.log('onUnmount');
	}, []);

	const handleFormatingAddress = ({ lat, lng, formatted_address, address_components }) => {
		console.log('handleFormatingAddress');
		let country = '';
		let state = '';
		let city = '';
		let village = '';
		let district = '';
		let postalCode = '';

		address_components?.forEach((item) => {
			if (item.types.includes('country')) {
				country = item.long_name;
			}
			if (item.types.includes('administrative_area_level_1')) {
				state = item.long_name;
			}
			if (item.types.includes('administrative_area_level_2')) {
				city = item.long_name;
			}
			if (item.types.includes('administrative_area_level_3')) {
				village = item.long_name;
			}
			if (item.types.includes('administrative_area_level_4')) {
				district = item.long_name;
			}
			if (item.types.includes('postal_code')) {
				postalCode = item.long_name;
			}
		});
		const dataAddress = {
			address: formatted_address,
			latitude: lat,
			longitude: lng,
			country,
			state,
			city,
			village,
			district,
			postalCode,
		};

		setLocation({ lat, lng });
		setName(district || village);
		setValue(formatted_address);
		setAddress(dataAddress);
	};

	const handleDragEnd = async (data) => {
		console.log('handleDragEnd');
		const lat = data?.latLng?.lat();
		const lng = data?.latLng?.lng();
		getGeocode({ location: { lat, lng } }).then((results) => {
			const result = results[0];
			handleFormatingAddress({
				lat,
				lng,
				formatted_address: result?.formatted_address || '',
				address_components: result?.address_components || {},
			});
		});
		setIsVisibleFooter(true);
	};

	const handleInput = (e) => {
		console.log('handleInput: ', e.target.value);
		setValue(e.target.value);
	};

	const handleSelect = (data) => {
		console.log('handleSelect');
		const { description, place_id } = data;
		getGeocode({ placeId: place_id }).then((results) => {
			const result = results[0];
			const { lat, lng } = getLatLng(result);
			handleFormatingAddress({
				lat,
				lng,
				formatted_address: result?.formatted_address || '',
				address_components: result?.address_components || {},
			});
			setZoom(15);
		});
		clearSuggestions();
		setIsVisibleFooter(true);
	};

	const handleConfirmLocation = () => {
		console.log('handleConfirmLocation');
		setCookie('address', address?.address);
		setCookie('latitude', address?.latitude);
		setCookie('longitude', address?.longitude);
		setCookie('country', address?.country);
		setCookie('state', address?.state);
		setCookie('city', address?.city);
		setCookie('village', address?.village);
		setCookie('district', address?.district);
		setCookie('postalCode', address?.postalCode);
		backPath ? history.replace(`/${backPath}`) : history.push('/');
	};

  const handleGetCurrentLocation = async () => {
    console.log('handleGetCurrentLocation');
    const coords = await getCurrentLocation()
    console.log('coords: ', coords);
    if (!coords || !coords?.latitude || !coords?.longitude) return;
		getGeocode({ location: { lat: coords?.latitude, lng: coords?.longitude } }).then((results) => {
			const result = results[0];
			handleFormatingAddress({
				lat: coords?.latitude,
				lng: coords?.longitude,
				formatted_address: result?.formatted_address || '',
				address_components: result?.address_components || {},
			});
      setZoom(15);
		});
    setIsVisibleFooter(true);
  }

	const renderSuggestions = () => {
		return data.map((suggestion) => {
			const {
				place_id,
				structured_formatting: { main_text, secondary_text },
			} = suggestion;
			if (!secondary_text) {
				return null;
			}
			return (
				<div key={place_id} onClick={() => handleSelect(suggestion)}>
					<div className='cursor-pointer p-2 border-b-1 text-gray-900 text-sm hover:bg-blue-500 hover:text-white'>{`${main_text} - ${secondary_text}`}</div>
				</div>
			);
		});
	};

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<div className='h-screen w-full flex justify-center items-center'>
						<div className='relative h-screen w-full'>
							<div className='absolute z-10 mt-5 w-full'>
								<div className='flex mx-4'>
									<div className='mr-2 flex justify-center items-center cursor-pointer' onClick={() => backPath ? history.replace(`/${backPath}`) : history.push('/')}>
										<ArrowLeftIcon className='h-10 w-10 text-gray-900 bg-white rounded-full p-2' />
									</div>
									<div className='relative rounded-full shadow-sm w-full'>
										<input
											type='text'
											className='block w-full border-0 py-1.5 !pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 !m-0 text-xs !rounded-inherit'
											placeholder='Search location'
											value={value || ''}
											onChange={handleInput}
											disabled={isLoading}
										/>
										{value && (
											<div
												className='absolute inset-y-0 right-0 flex items-center mr-2 cursor-pointer'
												onClick={() => {
													setValue('');
                          setLocation(null);
													setIsVisibleFooter(false);
													setZoom(4);
													clearSuggestions();
												}}>
												<XCircleIcon className='text-gray-500 h-6' />
											</div>
										)}
									</div>
								</div>
								{status === 'OK' && !isVisibleFooter && <div className='my-2 mx-4 border-1 rounded-lg bg-white'>{renderSuggestions()}</div>}
							</div>
							<GoogleMap
								mapContainerClassName='h-full w-full'
								clickableIcons
								center={location ?? centerLocation}
								zoom={zoom}
								onClick={(e) => {
									handleOnClick(e);
								}}
								onLoad={onLoad}
								onUnmount={onUnmount}
								options={mapOptions}>
								{centerLocation && location && (
									<Marker
										position={location}
										draggable
										onDragEnd={(e) => {
											handleDragEnd(e);
										}}
									/>
								)}
							</GoogleMap>
							<Footer isVisible={isVisibleFooter} name={name} address={value} onClick={handleConfirmLocation} onClickCurrentLocation={handleGetCurrentLocation} />
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default CustomGoogleMap;

/* eslint-disable */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";

import { IonFooter } from "@ionic/react";
import "@ionic/react/css/core.css";

import cookie from "react-cookies";
import { format } from "date-fns";
import axios from "axios";
import Cart from "../Cart/Cart";
import {
  stripslashes,
  showPrice,
  encodeValue,
  showLoader,
  hideLoader,
  getCalculatedAmount,
  showAlert,
  showBtnLoader,
  hideBtnLoader,
  capitalizeFirstLetter,
  startRefreshToken,
  stopRefreshToken,
} from "../Settings/SettingHelper";
import {
  apiUrl,
  deliveryId,
  unquieID,
  baseUrl,
  voucherUrl,
} from "../Settings/Config";
import barrow from "../../common/images/back-arrow.svg";
import sampleimg from "../../common/images/sample.png";
import plusi from "../../common/images/plus.svg";
import minusi from "../../common/images/minus.svg";
import bike from "../../common/images/bike.svg";
import clock from "../../common/images/scheduleclock.svg";
import printer from "../../common/images/printer.svg";
import bin from "../../common/images/dustbin.svg";
import dbadge from "../../common/images/discount-badge.svg";
import tvoucher from "../../common/images/ticket-voucher.svg";
import paymethod from "../../common/images/pay-method.svg";
import closeicon from "../../common/images/close-icon.svg";
import ccicon from "../../common/images/credit-card-icnn.svg";
import gopy from "../../common/images/gopay.png";
import shoppay from "../../common/images/shopeepay.svg";
import one from "../../common/images/oneklik.png";
import bc from "../../common/images/bca.svg";
import closeIcon from "../../common/images/close-icon.svg";
import { getCookie, setCookie } from "../Helpers/SettingHelper";
import food from "../../common/images/food.png";
import Notes from "../Notes";

var qs = require("qs");
class Transactionorder extends Component {
  constructor(props) {
    super(props);
    var userID =
      cookie.load("customerID") !== "" &&
      typeof cookie.load("customerID") !== undefined &&
      typeof cookie.load("customerID") !== "undefined"
        ? cookie.load("customerID")
        : "";
    this.state = {
      userID: userID,
      cartdetails: [],
      displayCart: "",
      trigerCart: false,
      cartItemID: "",
      loader: true,
      openFilterSheet: true,
      openVehicleSheet: false,
      openPaymentSheet: false,
      deliveryPartnerList: [],
      selectedDeliveryPartner: "",
      paymentType: "",
      deliveryOrderID: "",
      discountDetails: getCookie("discountDetails") || [],
      currentPromo: "",
      typeIndex: "",
      vehicleIndex: "",
      PaymentRefrenceNo: "",
      deliveryZone: "",
      confirmDelivery: false,
      deliveryFee: 0,
    };
    this.handleChangeText = this.handleChangeText.bind(this);
    this.intervalId = null;
  }
  componentDidMount() {
    console.log("componentDidMount");

    $("body").addClass("hide-overlay");
    $("body").removeClass("bodybgdark");
    this.removeoveflow();
    this.loadPaymentRefrence();
    // this.loadZoneDetails();
    this.intervalId = startRefreshToken();
  }

  componentWillUnmount() {
    console.log("componentWillUnmount");
    stopRefreshToken(this.intervalId);
  }

  removeoveflow() {
    var $_this = this;
    /*  setTimeout(function () {
      $("body").css("overflow", "auto");
      $_this.removeoveflow();
    }, 2000); */
  }

  componentWillReceiveProps(PropsDt) {
    console.log("componentWillReceiveProps");
    if (this.state.cartdetails !== PropsDt.cartdetails) {
      if (Object.keys(PropsDt.cartdetails).length === 0) {
        this.props.history.push("./");
      } else {
        this.setState(
          { cartdetails: PropsDt.cartdetails, loader: false },
          function () {
            this.loadCartItem();
          }
        );
        setCookie(
          "storeAddress",
          PropsDt.cartdetails?.store?.[0]?.storeAddress
        );
      }
    }
  }
  loadPaymentRefrence() {
    var postObject = {
      unquieid: unquieID,
      customerID: cookie.load("customerID"),
    };

    axios
      .post(
        apiUrl + "Paymentuvcr/generatePaymentRefrence",
        qs.stringify(postObject)
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ PaymentRefrenceNo: res.data.paymentRefrence });
        }
      });
  }

  loadZoneDetails() {
    console.log('loadZoneDetails');
    var zoneID = cookie.load("delZoneID") || "";
    if (zoneID !== "") {
      axios
        .get(
          apiUrl +
            "store/getZoneDetails?unquieid=" +
            unquieID +
            "&zoneId=" +
            zoneID
        )
        .then((res) => {
          if (res.data.status === "ok") {
            this.setState({ deliveryZone: res.data.result });
          }
        });
    }
  }

  sateValChange = (field, value) => {
    this.setState({ [field]: value });
  };
  handleChangeText = (event) => {
    var name = event.target.name;
    var value = event.target.value;
    this.setState({ [name]: value });
  };
  gotoPromo() {
    $("html, body").animate(
      {
        scrollTop: $("#currentPromo").offset().top,
      },
      1000
    );
  }
  onChangeNotes() {
    this.setState({ trigerCart: true });
  }
  loadCartItem() {
    var displayCart = "";
    if (Object.keys(this.state.cartdetails)?.length > 0) {
      if (this.state.cartdetails.store?.length > 0) {
        displayCart = this.state.cartdetails.store.map((store) => {
          if (store.item?.length > 0) {
            return store.item.map((ProItem, proIndex) => {
              return (
                <li key={proIndex} id={"cart_" + ProItem.itemID}>
                  <div className="ot-top">
                    <div
                      style={{
                        height: "80px",
                        width: "80px",
                      }}
                    >
                      <img
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                        src={ProItem.itemImage || food}
                      />
                    </div>
                    <div className="otp-desc" style={{ paddingLeft: "10px" }}>
                      <div className="otp-desc-lhs">
                        <h3 className="ot-title">
                          {stripslashes(ProItem.itemName)}
                        </h3>
                        {ProItem.comboset.length > 0 && (
                          <div className="otp-desc-comob">
                            {ProItem.comboset.map((comboItem, comobIndex) => {
                              return (
                                <p key={comobIndex}>
                                  <strong>{comboItem.comboSetname}</strong>
                                  {comboItem.productDetails.length > 0 &&
                                    comboItem.productDetails.map(
                                      (comobPro, comboProIndex) => {
                                        return (
                                          <span key={comboProIndex}>
                                            {comboProIndex !== 0 && " + "}
                                            {stripslashes(comobPro.productName)}
                                            {parseInt(comobPro.quantity) >
                                              0 && (
                                              <>
                                                <b> x{comobPro.quantity}</b>
                                              </>
                                            )}
                                            {parseFloat(comobPro.productPrice) >
                                              0 && (
                                              <>
                                                (+
                                                {showPrice(
                                                  comobPro.productPrice
                                                )}
                                                )
                                              </>
                                            )}
                                          </span>
                                        );
                                      }
                                    )}
                                </p>
                              );
                            })}
                          </div>
                        )}
                      </div>
                      <div className="otp-desc-rhs">
                        <span>{showPrice(ProItem.itemTotalPrice)}</span>
                      </div>
                    </div>
                  </div>
                  <Notes
                    item_id={ProItem?.itemID}
                    notes={ProItem?.itemNotes || ""}
                    callback={() => this.onChangeNotes()}
                  />
                  <div className="ot-btm">
                    <div className="ot-btm-merge">
                      <div className="ot-btm-delete">
                        <a
                          href={void 0}
                          onClick={this.proQtyIncDecUpdate.bind(
                            this,
                            ProItem.itemID,
                            1,
                            store.storeID,
                            "decr"
                          )}
                        >
                          <img src={bin} />
                        </a>
                      </div>
                      <div className="pro-qtybx">
                        <div className="qty-bx">
                          <span
                            className="qty-minus"
                            onClick={this.proQtyIncDecUpdate.bind(
                              this,
                              ProItem.itemID,
                              ProItem.itemQuantity,
                              store.storeID,
                              "decr"
                            )}
                          >
                            <img src={minusi} />
                          </span>
                          <div className="input-quantity">
                            {ProItem.itemQuantity}
                          </div>
                          <span
                            className="qty-plus"
                            onClick={this.proQtyIncDecUpdate.bind(
                              this,
                              ProItem.itemID,
                              ProItem.itemQuantity,
                              store.storeID,
                              "inc"
                            )}
                          >
                            <img src={plusi} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            });
          }
        });
      }
    }
    hideLoader("cart_" + this.state.cartItemID);
    this.setState({ displayCart: displayCart, cartItemID: "" });
    this.newPartnerList();
  }
  proQtyIncDecUpdate(itemID, itemQuantity, storeID, type) {
    var updQty = 0;
    if (type === "inc") {
      updQty = parseInt(itemQuantity) + 1;
    } else {
      updQty = parseInt(itemQuantity) - 1;
    }
    this.setState({ cartItemID: itemID }, function () {
      showLoader("cart_" + itemID);
    });
    var postObject = {
      unquieid: unquieID,
      availabilityID: deliveryId,
      shopID: encodeValue(storeID),
      customerID: cookie.load("customerID"),
      itemID: itemID,
      quantity: updQty,
    };

    axios
      .post(apiUrl + "cart/updateCartItem", qs.stringify(postObject))
      .then((res) => {
        // hideLoader("proDtIndex-" + IndexFlg, "Idtext");

        if (res.data.status === "ok") {
          this.removeAllDiscount("promo");
          this.setState({ trigerCart: true });
          /*  removePromoCkValue();
            this.handleShowAlertFun(
              "Success",
              "Great choice! Item added to your cart."
            ); */
        } else if (res.data.status === "error") {
          /*   var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
            this.handleShowAlertFun("Error", errMsgtxt); */
        }

        return false;
      });
  }
  openVehicleType() {
    this.placeOrder("No", "forCheckingDelivery");
  }
  placeOrder(validdate = "No", otherTypes = "") {
    var priceDetail = getCalculatedAmount(
      this.state.cartdetails,
      this.state.selectedDeliveryPartner,
      this.state.deliveryZone
    );
    var orderDate = new Date();
    var products = [];
    var totalItem = 0;
    if (this.state.cartdetails.store.length > 0) {
      this.state.cartdetails.store.map((store) => {
        if (store.item.length > 0) {
          store.item.map((item) => {
            totalItem = parseInt(totalItem) + parseInt(item.itemQuantity);
            products.push({
              shopID: store.storeID,
              productID: item.productID,
              itemName: item.itemName,
              itemImage: item.itemImage,
              itemSKU: item.itemSKU,
              itemRemarks: item.itemNotes,
              itemQuantity: item.itemQuantity,
              itemPrice: item.itemUnitPrice,
              itemTotalPrice: item.itemTotalPrice,
              comobSet: item.comboset,
            });
          });
        }
      });
    }

    var postObject = {
      unquieid: unquieID,
      customerID: cookie.load("customerID"),
      deliveryCharge: priceDetail["deliveryCharge"],
      additionalDeliveryCharge: 0,
      taxCharge: 0,
      taxAmount: 0,
      promotions: this.state.discountDetails,
      subTotal: priceDetail["subTotal"],
      grandTotal: priceDetail["grandTotal"],
      totalDiscount: priceDetail["totalDiscount"],
      discountType:
        parseFloat(priceDetail["totalDiscount"]) > 0 ? "coupon" : "",
      totalItem: totalItem,
      instantOrder: "Yes",
      availabilityID: deliveryId,
      availabilityName: "Delivery",
      orderSource: "Web",
      firstName: cookie.load("firstName"),
      lastName: cookie.load("lastName"),
      email: cookie.load("email"),
      phone: cookie.load("primary_phone"),
      address: cookie.load("address"),
      village: getCookie('village'),
      district: getCookie('district'),
      city: getCookie('city'),
      state: getCookie('state'),
      country: getCookie('country'),
      address: getCookie('address'),
      latitude: getCookie("latitude"),
      longitude: getCookie("longitude"),
      addressID: cookie.load("addressID"),
      billingaddress: cookie.load("storeAddress"),
      // billingpostalCode: cookie.load("deliverypostalCode"),
      products: JSON.stringify(products),
      deliveryPartner: JSON.stringify(this.state.selectedDeliveryPartner),
      discountDetails:
        priceDetail["discountDetails"].length > 0
          ? JSON.stringify(priceDetail["discountDetails"])
          : [],
      deliveryOrderID: this.state.deliveryOrderID,
      siteURL: baseUrl + "process-order",
      failedURL: baseUrl + "transaction-order?paymentstatus=faild",
      paymentMethod: "2",
      paymentGetway: "SNAP",
      paymentReferenceID: this.state.PaymentRefrenceNo,
      validateOrder: "Yes",
      deliveryZone: this.state.deliveryZone,
      packagingFee: Number(priceDetail["packagingFee"]) || 0,
      platformFee: Number(priceDetail["platformFee"]) || 0,
      deliveryFee: Number(priceDetail["deliveryFee"]) || 0,
      subsidy: this.state.selectedDeliveryPartner?.subsidy,
      deliverySubsidies: this.state.selectedDeliveryPartner?.subsidy
        ? this.state.delivery_subsidies
        : 0,
    };
    var postData = { ...postObject };

    if (otherTypes === "forCheckingDelivery") {
      showLoader("delivery_type");
      this.loadPartnersList(postData);
      return false;
    } else if (otherTypes === "forcreateDelivery") {
      this.createDelivery(postData);
      return false;
    } else if (otherTypes === "processpayment") {
      if (this.state.selectedDeliveryPartner === "") {
        showAlert("error", "Please choose any one vehicle");
        return false;
      } else if (this.state.confirmDelivery === false) {
        showAlert("error", "Please confirm any one vehicle");
        return false;
      } /* else if (this.state.paymentType === "") {
        showAlert("error", "Please choose payment Method");
        return false;
      } */
      showLoader("pay_order");
      this.createDelivery(postData);

      // showLoader("pay_order");
      // window.sessionStorage.setItem('orderData', qs.stringify(postObject));
      // this.processPayment(postData);
      return false;
    }
    if (this.state.selectedDeliveryPartner === "") {
      showAlert("error", "Please choose any one vehicle");
      return false;
    }

    showLoader("pay_order");
    axios
      .post(apiUrl + "orders/placeorder", qs.stringify(postObject))
      .then((res) => {
        hideLoader("pay_order");
        if (res.data.status === "ok") {
          this.props.history.push(
            "/order-completed/" + res.data.result.local_order_no
          );
        } else if (res.data.status === "error") {
        }
      });
  }
  loadPartnersList(postObject) {
    console.log("loadPartnersList");
    this.setState(
      {
        selectedDeliveryPartner: "",
        deliveryPartnerList: [],
        typeIndex: "",
        vehicleIndex: "",
        deliveryFee: 0,
      },
      () => {
        axios
          .post(
            apiUrl + "deliverypartners/loadPartnersList",
            qs.stringify(postObject)
          )
          .then((res) => {
            hideLoader("delivery_type");
            if (res.data.status === "ok") {
              this.setState(
                {
                  deliveryPartnerList: res.data.result,
                  openVehicleSheet: true,
                  deliveryFee: res.data.delivery_fee,
                },
                () => {
                  setCookie("deliveryFee", this.state.deliveryFee);
                  $("body").addClass("bodybgdark");
                }
              );
            } else if (res.data.status === "error") {
              showAlert("Alert", res.data.message);
            }
          });
      }
    );
  }
  selectDeliveryPartner(deliveryPartner, typeIndex, vehicleIndex) {
    console.log("selectDeliveryPartner");
    this.setState({
      selectedDeliveryPartner: {
        amount: deliveryPartner.amount,
        deliveryPartnerId: deliveryPartner.deliveryPartnerId,
        serviceType: deliveryPartner.serviceType,
        vehicleType: deliveryPartner.vehicleType,
        name: deliveryPartner.name,
        subsidy: deliveryPartner?.subsidy || false,
      },
      typeIndex: typeIndex,
      vehicleIndex: vehicleIndex,
    });
  }
  confirmDeliveryPartner() {
    console.log("confirmDeliveryPartner");
    showBtnLoader("confirmDelivery");
    this.setState({ confirmDelivery: true, openVehicleSheet: false });
    $("body").removeClass("bodybgdark");
  }

  createDelivery(postObject) {
    console.log("createDelivery");
    axios
      .post(
        apiUrl + "deliverypartners/createPartnerOrder",
        qs.stringify(postObject)
      )
      .then((res) => {
        hideBtnLoader("confirmDelivery");
        if (res.data.status === "ok") {
          this.setState(
            {
              deliveryOrderID: res.data.deliveryOrderID,
              openVehicleSheet: false,
            },
            () => {
              $("body").removeClass("bodybgdark");
              postObject = {
                ...postObject,
                deliveryOrderID: res.data.deliveryOrderID,
              };
              this.processPayment(postObject);
            }
          );
        } else if (res.data.status === "error") {
          hideLoader("pay_order");
          showAlert("error", res.data.message);
        }
      });
  }
  processPayment(postObject) {
    console.log("processPayment");
    postObject["token"] = cookie.load("token");
    postObject["refresh_token"] = cookie.load("refresh_token");

    axios
      .post(apiUrl + "paymentuvcr/processPayment", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "ok") {
          cookie.save("paymentOrderID", res.data.paymentOrderID, { path: "/" });
          setTimeout(function () {
            hideLoader("delivery_type");
            window.location.href = res.data.redirectURL;
          }, 2000);
        } else if (res.data.status === "error") {
          showAlert("error", res.data.message);
          hideLoader("pay_order");
        }
      });
  }
  // applyDiscount() {
  // 	if (this.state.currentPromo !== '') {
  // 		var discountDetails = this.state.discountDetails;
  // 		var error = 0;
  // 		if (
  // 			discountDetails.map((item) => {
  // 				if (item.promotion_code.toLowerCase() === this.state.currentPromo.toLowerCase()) {
  // 					error++;
  // 				}
  // 			})
  // 		)
  // 			if (error === 0) {
  // 				showLoader('use_discount');
  // 				var grandTotal = this.state.cartdetails.grandTotal;
  // 				var totalItem = this.state.cartdetails.totalItem;
  // 				var products = this.loadProducts(this.state.cartdetails.store);
  // 				var postObject = {
  // 					unquieid: unquieID,
  // 					customerID: this.state.userID,
  // 					availabilityID: deliveryId,
  // 					subtotal: grandTotal,
  // 					itemQuantity: totalItem,
  // 					categoryID: products.length > 0 ? products.join(',') : '',
  // 					discountcode: this.state.currentPromo,
  // 				};
  // 				axios.post(apiUrl + 'coupon/applyPromo', qs.stringify(postObject)).then((res) => {
  // 					hideLoader('use_discount');
  // 					if (res.data.status === 'ok') {
  // 						res.data.discountDetails.map((item) => {
  // 							discountDetails.push(item);
  // 						});
  // 						cookie.save('discountDetails', discountDetails, {
  // 							path: '/',
  // 						});
  // 						this.setState({
  // 							discountDetails: discountDetails,
  // 							currentPromo: '',
  // 						});
  // 						showAlert('success', 'Discount offer applied successfully');
  // 					} else if (res.data.status === 'warning') {
  // 						showAlert('error', res.data.message);
  // 					} else if (res.data.status === 'error') {
  // 						showAlert('error', res.data.message);
  // 					}
  // 				});
  // 			} else {
  // 				showAlert('error', this.state.currentPromo + ' Already Used');
  // 			}
  // 	} else {
  // 		showAlert('error', 'Please enter promo code');
  // 	}
  // }
  loadProducts(products) {
    var result = [];
    if (products.length > 0) {
      products.map((cartitem) => {
        if (cartitem.item.length > 0) {
          cartitem.item.map((item) => {
            result.push(
              item.productID +
                "|" +
                item.itemTotalPrice +
                "|" +
                item.itemQuantity
            );
          });
        }
      });
    }
    return result;
  }
  removeDiscount(itemIndex) {
    var discountDetails = this.state.discountDetails;
    var updateDiscount = [];
    discountDetails.map((item, index) => {
      if (itemIndex !== index) {
        updateDiscount.push(item);
      }
    });
    this.setState({ discountDetails: updateDiscount });
    cookie.save("discountDetails", updateDiscount, {
      path: "/",
    });
  }
  removeAllDiscount(type) {
    if (type === "promo") {
      cookie.remove("discountDetails", {
        path: "/",
      });
      this.setState({ discountDetails: [] });
    }
  }
  selectPaymentMothod() {
    this.setState({ paymentType: "GOPAY" });
    /* this.setState({ openPaymentSheet: true }); */
  }
  confirmPaymentMothod() {
    this.setState({ openPaymentSheet: false });
  }
  selectPaymentType(paymentMethod) {
    this.setState({ paymentType: paymentMethod });
  }
  closeVhicleType() {
    this.setState({ openVehicleSheet: false }, () => {
      $("body").removeClass("bodybgdark");
    });
  }

  //new load partner list
  newPartnerList() {
    console.log("load newPartnerList");
    var priceDetail = getCalculatedAmount(
      this.state.cartdetails,
      this.state.selectedDeliveryPartner,
      this.state.deliveryZone
    );
    var products = [];
    var totalItem = 0;
    if (this.state.cartdetails.store.length > 0) {
      this.state.cartdetails.store.map((store) => {
        if (store.item.length > 0) {
          store.item.map((item) => {
            totalItem = parseInt(totalItem) + parseInt(item.itemQuantity);
            products.push({
              shopID: store.storeID,
              productID: item.productID,
              itemName: item.itemName,
              itemImage: item.itemImage,
              itemSKU: item.itemSKU,
              itemRemarks: item.itemNotes,
              itemQuantity: item.itemQuantity,
              itemPrice: item.itemUnitPrice,
              itemTotalPrice: item.itemTotalPrice,
              comobSet: item.comboset,
            });
          });
        }
      });
    }

    var postObject = {
      unquieid: unquieID,
      customerID: cookie.load("customerID"),
      deliveryCharge: priceDetail["deliveryCharge"],
      additionalDeliveryCharge: 0,
      taxCharge: 0,
      taxAmount: 0,
      promotions: this.state.discountDetails,
      subTotal: priceDetail["subTotal"],
      grandTotal: priceDetail["grandTotal"],
      totalDiscount: priceDetail["totalDiscount"],
      discountType:
        parseFloat(priceDetail["totalDiscount"]) > 0 ? "coupon" : "",
      totalItem: totalItem,
      instantOrder: "Yes",
      availabilityID: deliveryId,
      availabilityName: "Delivery",
      orderSource: "Web",
      firstName: cookie.load("firstName"),
      lastName: cookie.load("lastName"),
      email: cookie.load("email"),
      phone: cookie.load("primary_phone"),
      village: getCookie('village'),
      district: getCookie('district'),
      city: getCookie('city'),
      state: getCookie('state'),
      country: getCookie('country'),
      address: getCookie('address'),
      latitude: getCookie("latitude"),
      longitude: getCookie("longitude"),
      addressID: cookie.load("addressID"),
      billingaddress: cookie.load("storeAddress"),
      // billingpostalCode: cookie.load("deliverypostalCode"),
      products: JSON.stringify(products),
      deliveryPartner: JSON.stringify(this.state.selectedDeliveryPartner),
      discountDetails:
        priceDetail["discountDetails"].length > 0
          ? JSON.stringify(priceDetail["discountDetails"])
          : [],
      deliveryOrderID: this.state.deliveryOrderID,
      siteURL: baseUrl + "process-order",
      failedURL: baseUrl + "transaction-order?paymentstatus=faild",
      paymentMethod: "2",
      paymentGetway: "SNAP",
      paymentReferenceID: this.state.PaymentRefrenceNo,
      validateOrder: "Yes",
      deliveryZone: this.state.deliveryZone,
      packagingFee: Number(priceDetail["packagingFee"]) || 0,
      platformFee: Number(priceDetail["platformFee"]) || 0,
      deliveryFee: Number(priceDetail["deliveryFee"]) || 0,
    };
    var postData = { ...postObject };

    this.setState(
      {
        selectedDeliveryPartner: "",
        deliveryPartnerList: [],
        typeIndex: "",
        vehicleIndex: "",
        deliveryFee: 0,
      },
      () => {
        axios
          .post(
            apiUrl + "deliverypartners/loadPartnersList",
            qs.stringify(postData)
          )
          .then((res) => {
            if (res.data.status === "ok") {
              this.setState(
                {
                  deliveryPartnerList: res.data.result,
                  deliveryFee: res.data.delivery_fee,
                  delivery_subsidies: res.data.delivery_subsidies,
                },
                () => {
                  setCookie("deliveryFee", this.state.deliveryFee);
                }
              );
            } else if (res.data.status === "error") {
              showAlert("Alert", res.data.message);
            }
          });
      }
    );
  }

  openPartnerList() {
    $("body").addClass("bodybgdark");
    this.setState({
      openVehicleSheet: true,
    });
  }

  render() {
    var priceDetail = getCalculatedAmount(
      this.state.cartdetails,
      this.state.selectedDeliveryPartner,
      this.state.deliveryZone
    );

    return (
      <div className="main-div">
        <Cart
          sateValChange={this.sateValChange}
          trigerCart={this.state.trigerCart}
        />
        <div className="header-action header-action-center">
          <div className="container">
            <div className="hac-lhs">
              <Link to={"/"} className="arrow-back">
                <img src={barrow} />
              </Link>
            </div>
            <div className="ha-middle">Transaction Order</div>
            <div className="ha-rhs ha-rhs-link"></div>
          </div>
        </div>
        <div className="content-body rel">
          <div className="container">
            <div className="order-loc-box">
              <div className="order-loc-box-one">
                <ul>
                  <li>
                    <a href={void 0}>
                      <span></span>
                      <div>
                        <div className="address-title">From</div>
                        {cookie.load("storeAddress")}
                      </div>
                    </a>
                  </li>
                  <li>
                    <a onClick={() => this.props.history.push('/google-map?back=transaction-order')}>
                      <div>
                        <div className="address-title">To</div>
                        <span></span> {getCookie('address')}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="order-loc-box-two">
                <ul>
                  <li className="delivery-type">
                    <a href={void 0}>
                      <img src={clock} />
                      <strong>Delivery Type</strong>
                      <span>Instant</span>
                    </a>
                  </li>
                  <li id="delivery_type">
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.openPartnerList();
                      }}
                    >
                      <img src={bike} />
                      <strong>Vehicle Type</strong>
                      <span>
                        {this.state.selectedDeliveryPartner !== "" &&
                        this.state.confirmDelivery === true
                          ? this.state.selectedDeliveryPartner.name
                          : "Choose vehicle type"}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="detail-transaction">
              <div className="dt-header">
                <h2>Detail Transaction</h2>
              </div>
              <div className="dt-body">
                <span>
                  {capitalizeFirstLetter(cookie.load("firstName"))}{" "}
                  {cookie.load("lastName") !== "" &&
                  cookie.load("lastName") !== null &&
                  cookie.load("lastName") !== "null"
                    ? capitalizeFirstLetter(cookie.load("lastName"))
                    : ""}{" "}
                  {cookie.load("primary_phone")}
                  {this.state.PaymentRefrenceNo !== "" && (
                    <>
                      <br />
                      No.order: {this.state.PaymentRefrenceNo}
                    </>
                  )}
                </span>

                <div className="dt-body-img">
                  <img src={printer} />
                </div>
              </div>
            </div>
            <div className="product-order">
              <ul>
                {this.state.loader === true
                  ? Array(1, 2, 3).map((item) => {
                      return (
                        <li key={item}>
                          <ContentLoader viewBox="0 0 380 70">
                            <rect
                              x="0"
                              y="0"
                              rx="5"
                              ry="5"
                              width="70"
                              height="70"
                            />
                            <rect
                              x="80"
                              y="17"
                              rx="4"
                              ry="4"
                              width="300"
                              height="13"
                            />
                            <rect
                              x="80"
                              y="40"
                              rx="3"
                              ry="3"
                              width="250"
                              height="10"
                            />
                          </ContentLoader>
                        </li>
                      );
                    })
                  : this.state.displayCart}
              </ul>
            </div>
            {/* <div className='promo-order-boxes'>
							<ul>
								<li>
									<h4>Promo Code</h4>
									<div className='promo-order' id='use_discount'>
										<div className='pro-form-inner'>
											<img src={dbadge} className='dbadge' />
											<input
												type='text'
												placeholder='Enter the promo code'
												value={this.state.currentPromo}
												name='currentPromo'
												id='currentPromo'
												onChange={this.handleChangeText}
												onClick={this.gotoPromo.bind(this)}
											/>
										</div>
										<a href={void 0} onClick={this.applyDiscount.bind(this)} className='button'>
											Apply
										</a>
									</div>
									{this.state.discountDetails.length > 0 &&
										this.state.discountDetails.map((item, index) => {
											return (
												<div className='promo-order promo-order-applied'>
													<div className='pro-form-inner'>
														<img src={dbadge} className='dbadge' />
														<span>{item.promotion_code}</span>
													</div>
													<a href={void 0} onClick={this.removeDiscount.bind(this, index)} className='alink'>
														<img src={closeicon} className='' />
													</a>
												</div>
											);
										})}
								</li>
								{/*<li>
                  <h4>Vouchers</h4>
                  <div className="voucher-order">
                    <Link to={"/my-voucher"} className="vo-link">
                      <div className="voucher-order-inner">
                        <img src={tvoucher} className="tvoucher" />
                        <span>Get more discount offer</span>
                      </div>
                    </Link>
                  </div>
                  <div className="voucher-order vo-applied">
                    <a href="#" className="vo-link">
                      <div className="voucher-order-inner">
                        <img src={tvoucher} className="tvoucher" />
                        <span>Voucher selected (2)</span>
                      </div>
                    </a>
                  </div>
                  <div className="vo-applied-msg">
                    Yeay! You have saved IDR 12,500
                  </div>
                  </li>*/}
            {/*  <li>
                  <h4>Payment Method</h4>
                  <div className="voucher-order">
                    <a
                      href={void 0}
                      onClick={this.selectPaymentMothod.bind(this)}
                      className="vo-link"
                    >
                      <div className="paym-order-inner">
                        <img src={paymethod} className="paym" />
                        <span>
                          Payment Method
                          {this.state.paymentType !== ""
                            ? ": " + this.state.paymentType
                            : ""}
                        </span>
                      </div>
                    </a>
                  </div>
                </li>
							</ul>
						</div> */}
            {parseFloat(priceDetail["totalItem"]) > 0 && (
              <div className="transaction-summary">
                <h4>Summary Payment</h4>
                <ul>
                  <li>
                    <span>Subtotal ({priceDetail["totalItem"]} Menu)</span>
                    <strong>{showPrice(priceDetail["subTotal"])}</strong>
                  </li>
                  {parseFloat(priceDetail["deliveryCharge"]) > 0 && (
                    <li>
                      <span>Delivery Fee</span>
                      <strong>
                        {showPrice(
                          parseFloat(priceDetail["deliveryCharge"]) +
                            parseFloat(priceDetail["deliveryFee"])
                        )}
                      </strong>
                    </li>
                  )}
                  {parseFloat(priceDetail["packagingFee"]) > 0 && (
                    <li>
                      <span>Packaging Fee</span>
                      <strong>{showPrice(priceDetail["packagingFee"])}</strong>
                    </li>
                  )}
                  {parseFloat(priceDetail["platformFee"]) > 0 && (
                    <li>
                      <span>Platform Fee</span>
                      <strong>{showPrice(priceDetail["platformFee"])}</strong>
                    </li>
                  )}
                  {this.state.discountDetails.length > 0 &&
                    this.state.discountDetails.map((item) => {
                      return (
                        <li className="ts-discount">
                          <span>Discount ({item.promotion_code})</span>
                          <strong>-{showPrice(item.promotion_amount)}</strong>
                        </li>
                      );
                    })}
                  {/* <li className="ts-discount">
                    <span>Voucher KFC Rp 100.000</span>
                    <strong>-Rp 100.000</strong>
                  </li>
                  <li className="ts-discount">
                    <span>Voucher Discount UV 50%</span>
                    <strong>-Rp 50.000</strong>
                  </li> */}
                  {parseFloat(priceDetail["grandTotal"]) > 0 && (
                    <li className="ts-total">
                      <span>Total Transaction</span>
                      <strong>{showPrice(priceDetail["grandTotal"])}</strong>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
        <footer className="footer-main">
          {this.state.openFilterSheet === true && (
            <IonFooter collapse="fade">
              <div className="pay-full-btn">
                <a
                  href={void 0}
                  className="button"
                  id="pay_order"
                  onClick={this.placeOrder.bind(this, "No", "processpayment")}
                >
                  Pay
                </a>
              </div>
            </IonFooter>
          )}
          {this.state.openVehicleSheet === true && (
            <IonFooter collapse="fade">
              <div className="vehilce-type">
                <div className="vt-header textcenter">
                  <h2>Vehicle Type</h2>
                  <img
                    src={closeIcon}
                    alt="close"
                    className="icon-footer-close"
                    onClick={this.closeVhicleType.bind(this)}
                  />
                </div>
                <div className="vehicle-list">
                  {this.state.deliveryPartnerList.length > 0 ? (
                    <ul>
                      {this.state.deliveryPartnerList.map((item, index) => {
                        return (
                          <li key={index}>
                            <div className="accordion-head">
                              <h3>{item.vehicleType}</h3>
                              <p>
                                Ideal for small to medium items | up to 5 kg
                              </p>
                            </div>
                            <div className="accordion-body">
                              {item.vehicle.length > 0 && (
                                <ul>
                                  {item.vehicle.map((VItem, VIndex) => {
                                    return (
                                      <li
                                        key={VIndex}
                                        onClick={this.selectDeliveryPartner.bind(
                                          this,
                                          VItem,
                                          index,
                                          VIndex
                                        )}
                                        className={
                                          this.state.typeIndex === index &&
                                          this.state.vehicleIndex === VIndex
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        {VItem.iconUrl !== "" && (
                                          <figure>
                                            <img src={VItem.iconUrl} />
                                          </figure>
                                        )}
                                        <div className="traveling-desc">
                                          <strong>
                                            {VItem.name}
                                            <p>{VItem.serviceTypeName}</p>
                                          </strong>

                                          <span>
                                            {VItem.subsidy && (
                                              <span
                                                className="special-price"
                                                style={{ display: "block" }}
                                              >
                                                {showPrice(
                                                  VItem.amount +
                                                    this.state.deliveryFee +
                                                    this.state
                                                      .delivery_subsidies
                                                )}
                                              </span>
                                            )}
                                            <span>
                                              {showPrice(
                                                VItem.amount +
                                                  this.state.deliveryFee
                                              )}
                                            </span>
                                          </span>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <div class="custom-loader"></div>
                  )}
                </div>
              </div>
              <a
                href={void 0}
                onClick={this.confirmDeliveryPartner.bind(this)}
                id="confirmDelivery"
                className="button button-full"
              >
                Confirm
              </a>
            </IonFooter>
          )}
          {this.state.openPaymentSheet === true && (
            <IonFooter collapse="fade">
              <div className="paymnt-type">
                <div className="vt-header textcenter">
                  <h2>Payment Methods</h2>
                </div>
                <div className="paymnt-list">
                  <ul>
                    <li>
                      <div className="pt-cover">
                        <figure>
                          <img src={ccicon} />
                        </figure>
                        <h3>Debit/Credit Card</h3>
                        <p>Admin Fee Rp. 1.500</p>
                      </div>
                    </li>
                    <li
                      className={
                        this.state.paymentType === "VABCA" ? "active" : ""
                      }
                    >
                      <div
                        className="pt-cover"
                        onClick={this.selectPaymentType.bind(this, "VABCA")}
                      >
                        <figure>
                          <img src={bc} />
                        </figure>
                        <h3>BCA Virtual Account</h3>
                        <p>Admin Fee {showPrice(priceDetail["grandTotal"])}</p>
                      </div>
                    </li>
                    <li>
                      <div className="pt-cover">
                        <figure>
                          <img src={one} />
                        </figure>
                        <h3>Debit/Credit Card</h3>
                        <p>Admin Fee {showPrice(priceDetail["grandTotal"])}</p>
                      </div>
                    </li>
                    <li
                      className={
                        this.state.paymentType === "GOPAY" ? "active" : ""
                      }
                    >
                      <div
                        className="pt-cover"
                        onClick={this.selectPaymentType.bind(this, "GOPAY")}
                      >
                        <figure>
                          <img src={gopy} />
                        </figure>
                        <h3>Debit/Credit Card</h3>
                        <p>Admin Fee {showPrice(priceDetail["grandTotal"])}</p>
                      </div>
                    </li>
                    <li>
                      <div className="pt-cover">
                        <figure>
                          <img src={shoppay} />
                        </figure>
                        <h3>Debit/Credit Card</h3>
                        <p>Admin Fee {showPrice(priceDetail["grandTotal"])}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <a
                href={void 0}
                onClick={this.confirmPaymentMothod.bind(this)}
                className="button button-full"
              >
                Confirm
              </a>
            </IonFooter>
          )}
        </footer>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var cartdetailsArr = Array();
  if (Object.keys(state.cartdetails).length > 0) {
    if (state.cartdetails[0].status === "ok") {
      cartdetailsArr = state.cartdetails[0].result;
    }
  }
  return {
    cartdetails: cartdetailsArr,
  };
};

export default connect(mapStateTopProps)(withRouter(Transactionorder));
